<template>
  <div class="animated fadeIn">
    <b-card>
      <template v-slot:header>
        <h5>Laporan Pengiriman</h5>
      </template>
      <b-form @submit.prevent="onFilter">
        <div class="row mb-3">
          <div class="col-md-3 col-lg-2">
            <b-form-group label="Cabang Penemrima">
              <multiselect v-model="cabang" :options="option_cabang" :internal-search="true" :allow-empty="false"
                deselect-label="" select-label="" label="name" track-by="id" placeholder="pilih cabang" />
            </b-form-group>
          </div>
          <div class="col-md-3 col-lg-2">
            <b-form-group label="Cabang Pengirim">
              <multiselect v-model="cabang_penerima" :options="option_cabang" :internal-search="true" :allow-empty="false"
                deselect-label="" select-label="" label="name" track-by="id" placeholder="pilih cabang" />
            </b-form-group>
          </div>
          <div class="col-md-4 col-lg-4">
            <b-form-group label="Filter tanggal">
              <date-range-picker class="w-100" v-model="dateRange" ref="picker"
                :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" :minDate="null" :maxDate="new Date()"
                :singleDatePicker="false" :timePicker24Hour="true" :showWeekNumbers="true" :showDropdowns="true"
                :autoApply="true" @select="handleSelectDate">
              </date-range-picker>
            </b-form-group>
          </div>
          <div class="col-md-3 col-lg-2">
            <b-form-group label="Status">
              <multiselect v-model="status" :options="option_status" :internal-search="true" :allow-empty="false"
                deselect-label="" select-label="" label="name" track-by="id" placeholder="pilih status" />
            </b-form-group>
          </div>
          <div class="col d-flex justify-content-end justify-content-md-start align-items-center">
            <b-button :disabled="isLoading" type="submit" class="mt-2 mr-2" variant="pgiBtn">
              Search
            </b-button>
            <b-button class="mt-2" variant="pgiBtn" name="resetTableBtn" :disabled="isLoading" @click="onReset()">
              Reset
            </b-button>
          </div>
        </div>
      </b-form>
    </b-card>
  </div>
</template>
  
<script>
import DateRangePicker from 'vue2-daterange-picker'
import { mapState } from 'vuex';
export default {
  components: { DateRangePicker },
  data() {
    return {
      role: localStorage.getItem("role"),
      dateRange: { startDate: null, endDate: null },
      startDate: null,
      endDate: null,
      cabang: null,
      cabang_penerima: null,
      kategori_barang: null,
      status: null
    }
  },
  methods: {
    handleSelectDate(value) {
      const startDate = this.$moment(value.startDate).format('YYYY-MM-DD')
      const endDate = this.$moment(value.endDate).format('YYYY-MM-DD')
      this.startDate = startDate
      this.endDate = endDate
    },
    async onFilter() {
      const filter = {
        search: this.search,
        status: this.status ? this.status.id : "",
        cabang_kode: this.cabang ? this.cabang.kode : "",
        cabang_kode_penerima: this.cabang_penerima ? this.cabang_penerima.kode : "",
        startDate: this.startDate,
        endDate: this.endDate,
      }
      this.$store.dispatch("getLaporanPengirimanBarang", filter)
    },
    onReset() {
      this.kategori_barang = null
      this.cabang = null
      this.cabang_penerima = null
      this.startDate = null
      this.dateRange = { startDate: null, endDate: null }
      this.endDate = null
    }
  },
  computed: {
    ...mapState("loading", ["isLoading"]),
    option_cabang() {
      const response = this.$store.getters.cabangLists;
      const data = response.map(item => {
        return {
          ...item,
          id: item.id,
          nama: item.nama,
        }
      })
      return data;
    },
    option_status() {
      return [
        { id: 0, name: 'Selisih' },
        { id: 1, name: "Belum Diproses" },
        { id: 2, name: "Dikirim" },
        { id: 3, name: "Diterima" },
      ]
    }
  },
  mounted() {
    this.$store.dispatch("getCabang")
  }
}
</script>